import { DataPacket_Kind,  RemoteParticipant, RemoteTrackPublication, Room, RoomEvent, setLogLevel, Track, VideoPresets } from 'livekit-client';
import { DisplayContext,  DisplayOptions,  LiveKitRoom } from '@livekit/react-components';
import { useState } from 'react';
import 'react-aspect-ratio/aspect-ratio.css';
import { useNavigate, useLocation } from 'react-router-dom';
//import React from 'react';




window.sendData = function (jsonStr:string){
  const encoder = new TextEncoder()
  const data = encoder.encode(jsonStr);
  window.room1.localParticipant.publishData(data, 0)
}


export const RoomPage = () => {
  //const [numParticipants, setNumParticipants] = useState(0);
 
  const [displayOptions] = useState<DisplayOptions>({
    stageLayout: 'grid',
    showStats: false,
  });
  
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const url = query.get('url');
  const token = query.get('token');
  //const recorder = query.get('recorder');
  var isload=0;

  if (!url || !token) {
    return <div>url and token are required</div>;
  }

  const onLeave = () => {
    navigate('/');
  };

 // const updateParticipantSize = (room: Room) => {
    //setNumParticipants(room.participants.size + 1);
 // };




 
 // const onParticipantDisconnected = (room: Room) => {
    //updateParticipantSize(room);

    
    /* Special rule for recorder */
   // if (recorder && parseInt(recorder, 10) === 1 && room.participants.size === 0) {
     // console.log('END_RECORDING');
    //}
  //};





  /*
  const updateOptions = (options: DisplayOptions) => {
    setDisplayOptions({
      ...displayOptions,
      ...options,
    });
  };
  */

  return (
    <DisplayContext.Provider value={displayOptions}>
      <div className="roomContainer">

       
        <LiveKitRoom 
          url={url}
          token={token}
          onConnected={(room) => {


            if(isload==1){
              return;
            }

            isload=1;

           
            setLogLevel('debug');
            onConnected(room, query);
            
            //room.on(RoomEvent.ParticipantConnected, () => updateParticipantSize(room));
            room.on(RoomEvent.ParticipantDisconnected, (participant?: RemoteParticipant) => {

              console.info('断开：'+participant);
              //@ts-ignore
              cs.ParticipantDisconnected();
            });
            room.on(RoomEvent.TrackStreamStateChanged, (RemoteTrack: RemoteTrackPublication, state?: Track.StreamState, participant?: RemoteParticipant) => {
                console.info(state);
                //alert(state);
                console.info(RemoteTrack);
                console.info(participant);
                if(state=="active"){
                  //@ts-ignore
                  cs.onVideoTrack();
                }else{
                  //@ts-ignore
                  cs.onVideoTrackOff()
                }

            });
            
            

            room.on(RoomEvent.DataReceived, (payload: Uint8Array, participant?: RemoteParticipant, kind?: DataPacket_Kind) => {
              const decoder = new TextDecoder()
              const strData = decoder.decode(payload)
              //alert(strData);
              console.info(participant?.name);
              console.info(kind?.toString);
              reciveData(strData);
            });
            




            window.room1=room;
           
          }}
          roomOptions={{
            adaptiveStream: isSet(query, 'adaptiveStream'),
            dynacast: isSet(query, 'dynacast'),
            publishDefaults: {
              simulcast: isSet(query, 'simulcast'),
            },
            videoCaptureDefaults: {
              resolution: VideoPresets.h720.resolution,
            },
          }}
          onLeave={onLeave}
        />
      </div>
      
    </DisplayContext.Provider>
  );


  function reciveData(rev:string){
    console.log(rev);
    //@ts-ignore
    cs.onReciveData(rev);
  }




};




async function onConnected(room: Room, query: URLSearchParams) {
  // make it easier to debug
  //alert("1234");

  (window as any).currentRoom = room;

  if (isSet(query, 'audioEnabled')) {
    const audioDeviceId = query.get('audioDeviceId');
    if (audioDeviceId && room.options.audioCaptureDefaults) {
      room.options.audioCaptureDefaults.deviceId = audioDeviceId;
    }
    await room.localParticipant.setMicrophoneEnabled(true);
  }

  if (isSet(query, 'videoEnabled')) {
    const videoDeviceId = query.get('videoDeviceId');
    if (videoDeviceId && room.options.videoCaptureDefaults) {
      room.options.videoCaptureDefaults.deviceId = videoDeviceId;
    }
    await room.localParticipant.setCameraEnabled(true);
  }


}

function isSet(query: URLSearchParams, key: string): boolean {
  return query.get(key) === '1' || query.get(key) === 'true';
}
